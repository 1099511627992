// Tabulator 반응형 (일반적인 Tabulator)
.responsive-tabulator {
    height: 440px;

    // Responsive style for screens with width up to 768px
    @media (max-width: 990px) {
        height: 345px;
    }

    @media (max-width: 768px) {
        height: 250px;
    }
}

.responsive-card-body {
    overflow-y: auto;
}

// 상세화면 하단에 있는 Tabulator
.detail-tabulator {
    height: 350px;

    // 추후 : 화면 조정을 통해 수정 필요
    @media (max-width: 990px) {
        height: 345px;
    }

    @media (max-width: 768px) {
        height: 250px;
    }
}