/* CustomToast.css */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }

    to {
        opacity: 0;
        transform: translateX(100%);
    }
}

.toast-fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

.toast-fade-out {
    animation: fadeOut 0.5s ease-in-out;
}