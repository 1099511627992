/*
   1.0   GLOBAL - Color system
   2.0   GLOBAL - RTL Settings
   3.0   GLOBAL - Component Color 
   4.0   GLOBAL - Link Color 
   5.0   GLOBAL - Grid & Container
   6.0   GLOBAL - Body
   7.0   GLOBAL - Font   
   8.0   GLOBAL - Border Color   
   9.0   GLOBAL - Radius   
   10.0  GLOBAL - Box Shadow   
   11.0  GLOBAL - Enable Caret / Negative Margin
   12.0  App - Header / Top Menu / Sidebar / Content / Page Header
   13.0  App - POS System
   14.0  App - Login Register
   15.0   UI - Buttons + Forms
   16.0   UI - Buttons
   17.0   UI - Forms
   18.0   UI - Form Check
   19.0   UI - Form Switch
   20.0   UI - Form Input Group
   21.0   UI - Form Select
   22.0   UI - Form Range
   23.0   UI - Form File
   24.0   UI - Form Floating
   25.0   UI - Form Feedback & Validation
   26.0   UI - Table
   27.0   UI - Pagination
   28.0   UI - Modal
   29.0   UI - List Group
   30.0   UI - Alert
   31.0   UI - Card
   32.0   UI - Accordion
   33.0   UI - Dropdown
   34.0   UI - Progress Bar
   35.0   UI - Toasts
   36.0   UI - Badges
   37.0   UI - Nav Link
   38.0   UI - Nav Tabs
   39.0   UI - Nav Pills
   40.0   UI - Navbar
   41.0   UI - Tooltip
   42.0   UI - Popover
   43.0   UI - Image Thumbnail
   44.0   UI - Figure
   45.0   UI - Breadcrumb
   46.0   UI - Carousel
   47.0   UI - Spinners
   48.0   UI - Close Button
   49.0   UI - Offcanvas
   50.0   UI - Code
   51.0   UI - Hr
   52.0   UI - Panel
*/



// 1.0 GLOBAL - Color system

$gray-100:    #f8f9fa !default;
$gray-200:    #e9ecef !default;
$gray-300:    #dee2e6 !default;
$gray-400:    #ced4da !default;
$gray-500:    #adb5bd !default;
$gray-600:    #6c757d !default;
$gray-700:    #495057 !default;
$gray-800:    #2d353c !default;
$gray-900:    #20252a !default;
$black:       #000000 !default;
$blue:        #348fe2 !default;
$cyan:        #49b6d6 !default;
$dark:        #2d353c !default;
$dark-darker: #1a2229 !default;
$green:       #32a932 !default;
$indigo:      #8753de !default;
$lime:        #90ca4b !default;
$light:       #f2f3f4 !default;
$orange:      #f59c1a !default;
$purple:      #727cb6 !default;
$pink:        #fb5597 !default;
$red:         #ff5b57 !default;
$silver:      #b6c2c9 !default;
$teal:        #00acac !default;
$white:       #FFFFFF !default;
$yellow:      #ffd900 !default;
$inverse:     $gray-800 !default;
$default:     $gray-200 !default;
$primary:     $blue !default;
$secondary:   $gray-600 !default;
$success:     $teal !default;
$info:        $cyan !default;
$warning:     $orange !default;
$danger:      $red !default;
$theme:       $teal !default;
$theme-color: $white !default;
$colors:      () !default;
$grays:       () !default;
$theme-colors: (
	"default":    $default,
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "inverse":    $inverse,
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "lime":       $lime,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800,
  "muted":      $gray-500,
	"black":      $black,
	"silver":     $silver,
	"gray-100":   $gray-100,
	"gray-200":   $gray-200,
	"gray-300":   $gray-300,
	"gray-400":   $gray-400,
	"gray-500":   $gray-500,
	"gray-600":   $gray-600,
	"gray-700":   $gray-700,
	"gray-800":   $gray-800,
	"gray-900":   $gray-900,
);

$blue-100:   tint-color($blue, 80%) !default;
$blue-200:   tint-color($blue, 60%) !default;
$blue-300:   tint-color($blue, 40%) !default;
$blue-400:   tint-color($blue, 20%) !default;
$blue-500:   $blue !default;
$blue-600:   shade-color($blue, 20%) !default;
$blue-700:   shade-color($blue, 40%) !default;
$blue-800:   shade-color($blue, 60%) !default;
$blue-900:   shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100:   tint-color($pink, 80%) !default;
$pink-200:   tint-color($pink, 60%) !default;
$pink-300:   tint-color($pink, 40%) !default;
$pink-400:   tint-color($pink, 20%) !default;
$pink-500:   $pink !default;
$pink-600:   shade-color($pink, 20%) !default;
$pink-700:   shade-color($pink, 40%) !default;
$pink-800:   shade-color($pink, 60%) !default;
$pink-900:   shade-color($pink, 80%) !default;

$red-100:    tint-color($red, 80%) !default;
$red-200:    tint-color($red, 60%) !default;
$red-300:    tint-color($red, 40%) !default;
$red-400:    tint-color($red, 20%) !default;
$red-500:    $red !default;
$red-600:    shade-color($red, 20%) !default;
$red-700:    shade-color($red, 40%) !default;
$red-800:    shade-color($red, 60%) !default;
$red-900:    shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100:  tint-color($green, 80%) !default;
$green-200:  tint-color($green, 60%) !default;
$green-300:  tint-color($green, 40%) !default;
$green-400:  tint-color($green, 20%) !default;
$green-500:  $green !default;
$green-600:  shade-color($green, 20%) !default;
$green-700:  shade-color($green, 40%) !default;
$green-800:  shade-color($green, 60%) !default;
$green-900:  shade-color($green, 80%) !default;

$teal-100:   tint-color($teal, 80%) !default;
$teal-200:   tint-color($teal, 60%) !default;
$teal-300:   tint-color($teal, 40%) !default;
$teal-400:   tint-color($teal, 20%) !default;
$teal-500:   $teal !default;
$teal-600:   shade-color($teal, 20%) !default;
$teal-700:   shade-color($teal, 40%) !default;
$teal-800:   shade-color($teal, 60%) !default;
$teal-900:   shade-color($teal, 80%) !default;

$cyan-100:   tint-color($cyan, 80%) !default;
$cyan-200:   tint-color($cyan, 60%) !default;
$cyan-300:   tint-color($cyan, 40%) !default;
$cyan-400:   tint-color($cyan, 20%) !default;
$cyan-500:   $cyan !default;
$cyan-600:   shade-color($cyan, 20%) !default;
$cyan-700:   shade-color($cyan, 40%) !default;
$cyan-800:   shade-color($cyan, 60%) !default;
$cyan-900:   shade-color($cyan, 80%) !default;



// 2.0 GLOBAL - RTL Settings

$enable-rtl:                        false !default;



// 3.0 GLOBAL - Component Color 

$component-bg:                      $white !default;
$component-color:                   $gray-900 !default;
$component-border-color:            $gray-400 !default;
$component-active-bg:               $blue !default;
$component-active-color:            $white !default;
$component-active-border-color:     $blue !default;
$component-hover-bg:                tint-color($gray-200, 25%) !default;
$component-hover-color:             $gray-900 !default;
$component-hover-border-color:      $gray-400 !default;
$component-disabled-color:          $gray-500 !default;
$component-disabled-bg:             $gray-200 !default;
$component-disabled-border-color:   $gray-400 !default;
$component-focus-border-color:      tint-color($component-active-bg, 25%) !default;
$component-secondary-bg:            $gray-400 !default;
$component-table-border-color:      $component-border-color !default;
$component-table-separator-color:   currentColor !default;
$component-dropdown-bg:             $white !default;
$component-dropdown-hover-bg:       tint-color($gray-200, 25%) !default;
$component-dropdown-border-color:   $gray-400 !default;
$component-modal-bg:                $white !default;
$component-modal-border-color:      $gray-400 !default;

$component-bg-dark-mode:                    $gray-800 !default;
$component-color-dark-mode:                 $white !default;
$component-border-color-dark-mode:          $gray-700 !default;
$component-hover-bg-dark-mode:              tint-color($gray-800, 15%) !default;
$component-hover-color-dark-mode:           $white !default;
$component-hover-border-color-dark-mode:    rgba($gray-300, .125) !default;
$component-disabled-bg-dark-mode:           $gray-700 !default;
$component-disabled-border-color-dark-mode: $gray-700 !default;
$component-disabled-color-dark-mode:        $gray-600 !default;
$component-dropdown-bg-dark-mode:           $gray-700 !default;
$component-dropdown-hover-bg-dark-mode:     $gray-600 !default;
$component-dropdown-border-color-dark-mode: $gray-600 !default;
$component-modal-bg-dark-mode:              $gray-700 !default;
$component-modal-border-color-dark-mode:    $gray-600 !default;
$component-secondary-bg-dark-mode:          $gray-600 !default;
$component-table-border-color-dark-mode:    $gray-700 !default;
$component-table-separator-color-dark-mode: $gray-500 !default;



// 4.0 GLOBAL - Link Color 

$link-color:                        $primary !default;
$link-decoration:                   underline !default;
$link-shade-percentage:             20% !default;
$link-hover-color:                  shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:             null !default;



// 5.0 GLOBAL - Grid & Container

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
  xxxl: 1800px
);
$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 960px,
  xl: 1170px
) !default;
$spacer:                            rem-default(15px) !default;
$grid-gutter-width:                 20px !default;



// 6.0 GLOBAL - Body

$body-bg:                           $gray-300 !default;
$body-color:                        $gray-800 !default;
$body-letter-spacing:               0 !default;

$body-bg-dark-mode:                 shade-color($gray-900, 30%) !default;
$body-color-dark-mode:              $gray-300 !default;



// 7.0 GLOBAL - Font

$font-family-base:                  'Noto Sans KR', sans-serif !default;
$font-size-base:                    rem-default(14px) !default;
$font-size-sm:					            rem-default(12px) !default;
$font-size-lg:					            rem-default(16px) !default;

$font-weight-normal:                400 !default;
$font-weight-bold:                  600 !default;
$font-weight-bolder:                700 !default;
$font-weight-base:                  $font-weight-normal !default;

$line-height-base:			            1.5 !default;
$line-height-sm:				            1.2 !default;
$line-height-lg:				            1.8 !default;

$headings-font-weight:              600 !default;
$headings-color:                    null !default;

$paragraph-margin-bottom:           $spacer !default;
$text-muted:                        $gray-500 !default;
$small-font-size:                   .875em !default;



// 8.0 GLOBAL - Border Color

$border-color:                      $component-border-color !default;
$border-color-dark-mode:            $gray-600 !default;
$border-width:                      1px !default;



// 9.0 GLOBAL - Radius

$border-radius:                     4px !default;
$border-radius-lg:                  6px !default;
$border-radius-sm:                  3px !default;



// 10.0 GLOBAL - Box Shadow

$box-shadow:                        0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-sm:                     0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-lg:                     0 1rem 3rem rgba($black, .175) !default;
$box-shadow-inset:                  inset 0 1px 2px rgba($black, .075) !default;



// 11.0 GLOBAL - Enable Caret / Negative Margin

$enable-caret:                      false !default;
$enable-negative-margins:           true !default;



// 12.0 App - Header / Top Menu / Sidebar / Content / Page Header

$app-boxed-layout-width:            rem(1280px) !default;

$app-header-bg:                     $white !default;
$app-header-height:                 rem(50px) !default;
$app-header-height-sm:              rem(50px) !default;
$app-header-box-shadow:             none !default;
$app-header-scroll-box-shadow:      0 0 16px rgba($black, .15) !default;
$app-header-inverse-bg:             $gray-900 !default;

$app-header-color:                  $gray-900 !default;
$app-header-hover-bg:               $light !default;
$app-header-mobile-divider-bg:      mix($white, $silver, 75%) !default;
$app-header-input-bg:               $light !default;
$app-header-input-border:           $light !default;
$app-header-divider-bg:             $gray-300 !default;

$app-header-bg-dark-mode:                 shade-color($gray-800, 15%) !default;
$app-header-color-dark-mode:              $white !default;
$app-header-input-bg-dark-mode:           shade-color($gray-900, 15%) !default;
$app-header-input-border-dark-mode:       shade-color($gray-900, 15%) !default;
$app-header-divider-bg-dark-mode:         $gray-700 !default;
$app-header-mobile-divider-bg-dark-mode:  $gray-900 !default;

$app-top-menu-height:               rem(40px) !default;
$app-top-menu-component-padding-x:  rem(20px) !default;
$app-top-menu-component-padding-y:  rem(10px) !default;

$app-sidebar-width:                 rem(220px) !default;
$app-sidebar-minified-width:        rem(60px) !default;
$app-sidebar-wide-width:            rem(250px) !default;
$app-sidebar-hover-width:           rem(60px) !default;
$app-sidebar-component-padding-x:   rem(20px) !default;
$app-sidebar-component-padding-y:   rem(7px) !default;
$app-sidebar-mobile-backdrop-bg:    rgba($gray-900, .5) !default;
// $app-sidebar-bg:                    $gray-800 !default;
$app-sidebar-bg:                    rgb(40, 106, 69) !default;
$app-sidebar-profile-bg:            $gray-900 !default;
$app-sidebar-profile-color:         $white !default;
$app-sidebar-profile-bg-image:      url(../images/cover-sidebar-user.jpg) !default;
$app-sidebar-component-color:       $white !default;
// $app-sidebar-active-component-bg:   $gray-900 !default;
$app-sidebar-active-component-bg:   #ffffff94 !default;
$app-sidebar-menu-header-color:     rgba($white, .45) !default;
$app-sidebar-menu-divider-bg:       $gray-900 !default;
$app-sidebar-menu-badge-bg:         $gray-900 !default;
$app-sidebar-minify-btn-bg:         darken($dark, 4%) !default;
$app-sidebar-minify-btn-hover-bg:   $gray-900 !default;
$app-sidebar-transparent-bg-image:  url(./images/cover-sidebar.jpg) !default;

$app-content-padding-x:             rem(30px) !default;
$app-content-padding-y:             rem(20px) !default;
$app-content-padding-x-sm:          rem(20px) !default;
$app-content-padding-y-sm:          rem(20px) !default;
$app-content-mobile-padding-x:      rem(20px) !default;
$app-content-mobile-padding-y:      rem(20px) !default;

$app-footer-border:                 var(--app-component-border-color) !default;
$app-footer-color:                  $gray-600 !default;

$app-page-cover:                    null !default;
$app-coming-soon-bg-cover:          url(../images/coming-soon.jpg) !default;
$app-boxed-layout-bg-cover:         url(../images/cover-pattern.png) !default;
$app-error-page-bg-cover:           url(../images/error-page-pattern.png) !default;    
$app-login-bg-cover:                url(./images/bg-login.png) !default; 
$app-login-bg-cover-dark-mode:      url(./images/bg-login-dark-mode.png) !default;
$app-profile-bg-cover:              url(../images/profile-cover.jpg) !default;

$app-loader-zindex:                   1050 !default;
$app-header-zindex:                   1020 !default;
$app-top-menu-zindex:                 1015 !default;
$app-sidebar-zindex:                  1010 !default;
$app-sidebar-mobile-backdrop-zindex:  1020 !default;
$app-sidebar-float-submenu-zindex:    1020 !default;
$app-content-zindex:                  1020 !default;
$app-theme-panel-zindex:              1035 !default;

$btn-scroll-to-top-zindex:          1020 !default;
$pace-loader-zindex:                1030 !default;
$panel-expand-zindex:               1080 !default;

$page-header-font-size:             rem(24px) !default;
$page-header-font-weight:           500 !default;
$page-header-line-height:           1.35 !default;



// 13.0 App - POS System

$pos-booking-header-height:         rem(52px) !default;
$pos-counter-header-height:         rem(52px) !default;
$pos-kitchen-header-height:         rem(52px) !default;
$pos-stock-header-height:           rem(52px) !default;
$pos-sidebar-menu-width:            160px !default;
$pos-sidebar-desktop-width:         360px !default;
$pos-bg:                            $gray-200 !default;
$pos-bg-dark-mode:                  shade-color($gray-900, 30%) !default;



// 14.0 App - Login Register

$login-v1-container-width:                      rem(400px) !default;
$login-v2-container-width:                      rem(400px) !default;
$login-register-news-feed-container-width:      rem(500px) !default;
$login-register-news-feed-container-width-sm:   rem(400px) !default;



// 15.0 UI - Buttons + Forms

$input-btn-padding-y:               rem-default(7px) !default;
$input-btn-padding-x:               .75rem !default;
$input-btn-font-family:             null !default;
$input-btn-font-size:               $font-size-base !default;
$input-btn-line-height:             $line-height-base !default;

$input-btn-focus-width:             .25rem !default;
$input-btn-focus-color-opacity:     .25 !default;
$input-btn-focus-color:             rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:              0 !default;
$input-btn-focus-box-shadow:        0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:            0.3125rem !default;
$input-btn-padding-x-sm:            0.625rem !default;
$input-btn-font-size-sm:            0.75rem !default;

$input-btn-padding-y-lg:            .5rem !default;
$input-btn-padding-x-lg:            1rem !default;
$input-btn-font-size-lg:            $font-size-lg !default;

$input-btn-border-width:            $border-width !default;



// 16.0 UI - Buttons

$btn-padding-y:                     $input-btn-padding-y !default;
$btn-padding-x:                     $input-btn-padding-x !default;
$btn-font-family:                   $input-btn-font-family !default;
$btn-font-size:                     $input-btn-font-size !default;
$btn-line-height:                   $input-btn-line-height !default;
$btn-white-space:                   null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:                  $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                  $input-btn-padding-x-sm !default;
$btn-font-size-sm:                  $input-btn-font-size-sm !default;

$btn-padding-y-lg:                  $input-btn-padding-y-lg !default;
$btn-padding-x-lg:                  $input-btn-padding-x-lg !default;
$btn-font-size-lg:                  $input-btn-font-size-lg !default;

$btn-border-width:                  $input-btn-border-width !default;

$btn-font-weight:                   $font-weight-bold !default;
$btn-box-shadow:                    none !default;
$btn-focus-width:                   $input-btn-focus-width !default;
$btn-focus-box-shadow:              $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:              .65 !default;
$btn-active-box-shadow:             inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:                    $link-color !default;
$btn-link-hover-color:              $link-hover-color !default;
$btn-link-disabled-color:           $gray-600 !default;

$btn-border-radius:                 $border-radius !default;
$btn-border-radius-sm:              $border-radius-sm !default;
$btn-border-radius-lg:              $border-radius-lg !default;

$btn-transition:                    color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:         15% !default;
$btn-hover-bg-tint-amount:          15% !default;
$btn-hover-border-shade-amount:     20% !default;
$btn-hover-border-tint-amount:      10% !default;
$btn-active-bg-shade-amount:        20% !default;
$btn-active-bg-tint-amount:         20% !default;
$btn-active-border-shade-amount:    25% !default;
$btn-active-border-tint-amount:     10% !default;



// 17.0 UI - Forms

$form-text-margin-top:              .25rem !default;
$form-text-font-size:               $small-font-size !default;
$form-text-font-style:              null !default;
$form-text-font-weight:             null !default;
$form-text-color:                   $text-muted !default;

$form-label-margin-bottom:          .25rem !default;
$form-label-font-size:              null !default;
$form-label-font-style:             null !default;
$form-label-font-weight:            $font-weight-bold !default;
$form-label-color:                  null !default;

$input-padding-y:                   $input-btn-padding-y !default;
$input-padding-x:                   $input-btn-padding-x !default;
$input-font-family:                 $input-btn-font-family !default;
$input-font-size:                   $input-btn-font-size !default;
$input-font-weight:                 $font-weight-bold !default;
$input-line-height:                 $input-btn-line-height !default;

$input-padding-y-sm:                $input-btn-padding-y-sm !default;
$input-padding-x-sm:                $input-btn-padding-x-sm !default;
$input-font-size-sm:                $input-btn-font-size-sm !default;

$input-padding-y-lg:                $input-btn-padding-y-lg !default;
$input-padding-x-lg:                $input-btn-padding-x-lg !default;
$input-font-size-lg:                $input-btn-font-size-lg !default;

$input-bg:                          var(--app-component-bg) !default;
$input-disabled-bg:                 var(--app-component-disabled-bg) !default;
$input-disabled-border-color:       null !default;

$input-color:                       var(--app-component-color) !default;
$input-border-color:                $border-color !default;
$input-border-width:                $input-btn-border-width !default;
$input-box-shadow:                  $box-shadow-inset !default;

$input-border-radius:               $border-radius !default;
$input-border-radius-sm:            $border-radius-sm !default;
$input-border-radius-lg:            $border-radius-lg !default;

$input-focus-bg:                    $input-bg !default;
$input-focus-border-color:          tint-color($component-active-bg, 25%) !default;
$input-focus-color:                 $input-color !default;
$input-focus-width:                 $input-btn-focus-width !default;
$input-focus-box-shadow:            $input-btn-focus-box-shadow !default;

$input-placeholder-color:           $gray-600 !default;
$input-plaintext-color:             var(--app-component-color) !default;

$input-height-border:               $input-border-width * 2 !default;

$input-height-inner:                add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:           add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:        add($input-line-height * .25em, $input-padding-y * .5) !default;

$input-height:                      add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                   add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                   add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                  border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-color-width:                  3rem !default;



// 18.0 UI - Form Check

$form-check-input-width:                        1.5em !default;
$form-check-min-height:                         $font-size-base * $line-height-base !default;
$form-check-padding-start:                      $form-check-input-width + .5em !default;
$form-check-margin-bottom:                      .125rem !default;
$form-check-label-color:                        null !default;
$form-check-label-cursor:                       null !default;
$form-check-transition:                         null !default;

$form-check-input-active-filter:                brightness(90%) !default;

$form-check-input-bg:                           $input-bg !default;
$form-check-input-border:                       1px solid rgba(var(--bs-black-rgb), .25) !default;
$form-check-input-border-radius:                .25em !default;
$form-check-radio-border-radius:                50% !default;
$form-check-input-focus-border:                 $input-focus-border-color !default;
$form-check-input-focus-box-shadow:             $input-btn-focus-box-shadow !default;

$form-check-input-checked-color:                $component-active-color !default;
$form-check-input-checked-bg-color:             $component-active-bg !default;
$form-check-input-checked-border-color:         $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color:          $component-active-color !default;
$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$form-check-input-disabled-opacity:             .5 !default;
$form-check-label-disabled-opacity:             $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity:         $btn-disabled-opacity !default;

$form-check-inline-margin-end:                  1rem !default;



// 19.0 UI - Form Switch

$form-switch-color:                 rgba($black, .25) !default;
$form-switch-width:                 2.3em !default;
$form-switch-padding-start:         $form-switch-width + .5em !default;
$form-switch-bg-image:              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-bg-image-dark-mode:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{rgba($white, .25)}'/></svg>") !default;
$form-switch-border-radius:         $form-switch-width !default;
$form-switch-transition:            background-position .15s ease-in-out !default;

$form-switch-focus-color:           $input-focus-border-color !default;
$form-switch-focus-bg-image:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

$form-switch-checked-color:         $component-active-color !default;
$form-switch-checked-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position:   right center !default;



// 20.0 UI - Form Input Group

$input-group-addon-padding-y:       $input-padding-y !default;
$input-group-addon-padding-x:       $input-padding-x !default;
$input-group-addon-font-weight:     $input-font-weight !default;
$input-group-addon-color:           var(--app-component-color) !default;
$input-group-addon-bg:              #{rgba(var(--app-component-border-color-rgb), 1)} !default;
$input-group-addon-border-color:    var(--app-component-border-color) !default;



// 21.0 UI - Form Select

$form-select-padding-y:                   $input-padding-y !default;
$form-select-padding-x:                   $input-padding-x !default;
$form-select-font-family:                 $input-font-family !default;
$form-select-font-size:                   $input-font-size !default;
$form-select-indicator-padding:           $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight:                 $input-font-weight !default;
$form-select-line-height:                 $input-line-height !default;
$form-select-color:                       $input-color !default;
$form-select-bg:                          $input-bg !default;
$form-select-disabled-color:              null !default;
$form-select-disabled-bg:                 $gray-200 !default;
$form-select-disabled-border-color:       $input-disabled-border-color !default;
$form-select-bg-position:                 right $form-select-padding-x center !default;
$form-select-bg-size:                     16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color:             $gray-600 !default;
$form-select-indicator:                   url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end:   $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position:      center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

$form-select-border-width:                $input-border-width !default;
$form-select-border-color:                $input-border-color !default;
$form-select-border-radius:               $border-radius !default;
$form-select-box-shadow:                  $box-shadow-inset !default;

$form-select-focus-border-color:          $input-focus-border-color !default;
$form-select-focus-width:                 $input-focus-width !default;
$form-select-focus-box-shadow:            0 0 0 $form-select-focus-width $input-btn-focus-color !default;

$form-select-padding-y-sm:                $input-padding-y-sm !default;
$form-select-padding-x-sm:                $input-padding-x-sm !default;
$form-select-font-size-sm:                $input-font-size-sm !default;

$form-select-padding-y-lg:                $input-padding-y-lg !default;
$form-select-padding-x-lg:                $input-padding-x-lg !default;
$form-select-font-size-lg:                $input-font-size-lg !default;

$form-select-transition:                  $input-transition !default;



// 22.0 UI - Form Range

$form-range-track-width:                    100% !default;
$form-range-track-height:                   .5rem !default;
$form-range-track-cursor:                   pointer !default;
$form-range-track-bg:                       var(--bs-light) !default;
$form-range-track-border-radius:            1rem !default;
$form-range-track-box-shadow:               $box-shadow-inset !default;

$form-range-thumb-width:                    1rem !default;
$form-range-thumb-height:                   $form-range-thumb-width !default;
$form-range-thumb-bg:                       var(--app-component-active-bg) !default;
$form-range-thumb-border:                   0 !default;
$form-range-thumb-border-radius:            1rem !default;
$form-range-thumb-box-shadow:               0 .1rem .25rem rgba($black, .1) !default;
$form-range-thumb-focus-box-shadow:         0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width:   $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:                tint-color($component-active-bg, 50%) !default;
$form-range-thumb-disabled-bg:              $gray-500 !default;
$form-range-thumb-transition:               background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;



// 23.0 UI - Form File

$form-file-button-color:            $input-color !default;
$form-file-button-bg:               #{rgba(var(--app-component-border-color-rgb), 1)} !default;
$form-file-button-hover-bg:         rgba(var(--app-component-border-color-rgb), .5) !default;



// 24.0 UI - Form Floating

$form-floating-height:              add(3.5rem, $input-height-border) !default;
$form-floating-line-height:         1.25 !default;
$form-floating-padding-x:           $input-padding-x !default;
$form-floating-padding-y:           1rem !default;
$form-floating-input-padding-t:     1.625rem !default;
$form-floating-input-padding-b:     .625rem !default;
$form-floating-label-opacity:       .65 !default;
$form-floating-label-transform:     scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-transition:          opacity .1s ease-in-out, transform .1s ease-in-out !default;



// 25.0 UI - Form Feedback & Validation

$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $font-size-base * .95 !default;
$form-feedback-font-style:          $form-text-font-style !default;
$form-feedback-valid-color:         $success !default;
$form-feedback-invalid-color:       $danger !default;

$form-feedback-icon-valid-color:     $form-feedback-valid-color !default;
$form-feedback-icon-valid:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:   $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
) !default;



// 26.0 UI - Table

$table-cell-padding-y:              .5rem !default;
$table-cell-padding-x:              .5rem !default;
$table-cell-padding-y-sm:           .25rem !default;
$table-cell-padding-x-sm:           .25rem !default;
$table-cell-vertical-align:         top !default;

$table-color:                       var(--app-component-color) !default;
$table-bg:                          transparent !default;
$table-accent-bg:                   transparent !default;
$table-th-font-weight:              null !default;

$table-striped-color:               $table-color !default;
$table-striped-bg-factor:           .7 !default;
$table-striped-bg:                  rgba($gray-200, $table-striped-bg-factor) !default;

$table-hover-color:                 $table-color !default;
$table-hover-bg-factor:             .85 !default;
$table-hover-bg:                    rgba($gray-200, $table-hover-bg-factor) !default;

$table-active-color:                $table-color !default;
$table-active-bg-factor:            1 !default;
$table-active-bg:                   rgba($gray-200, $table-active-bg-factor) !default;

$table-border-factor:               .1 !default;
$table-border-width:                $border-width !default;
$table-border-color:                var(--app-component-table-border-color) !default;

$table-striped-order:               odd !default;
$table-group-separator-color:       var(--app-component-table-separator-color) !default;
$table-caption-color:               $text-muted !default;

$table-bg-scale:                    -80% !default;
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
) !default;



// 27.0 UI - Pagination

$pagination-padding-y:              .375rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.35rem !default;

$pagination-font-weight:            $font-weight-bold !default;
$pagination-color:                  rgba(var(--app-component-color-rgb), .75) !default;
$pagination-bg:                     var(--app-component-bg) !default;
$pagination-border-width:           $border-width !default;
$pagination-border-radius:          $border-radius !default;
$pagination-margin-start:           $spacer * .4 !default;
$pagination-border-color:           var(--app-component-border-color) !default;

$pagination-focus-color:            $gray-900 !default;
$pagination-focus-bg:               $gray-200 !default;
$pagination-focus-box-shadow:       0 !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            var(--app-component-hover-color) !default;
$pagination-hover-bg:               var(--app-component-hover-bg) !default;
$pagination-hover-border-color:     var(--app-component-border-color) !default;

$pagination-active-color:           var(--app-component-active-color) !default;
$pagination-active-bg:              var(--app-component-active-bg) !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         var(--app-component-disabled-color) !default;
$pagination-disabled-bg:            var(--app-component-disabled-bg) !default;
$pagination-disabled-border-color:  var(--app-component-disabled-border-color) !default;

$pagination-transition:             color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$pagination-border-radius-sm:       $border-radius-sm !default;
$pagination-border-radius-lg:       $border-radius-lg !default;



// 28.0 UI - Modal

$modal-inner-padding:               $spacer !default;
$modal-footer-margin-between:       .5rem !default;
$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;
$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  var(--app-component-modal-bg) !default;
$modal-content-border-color:        var(--app-component-modal-border-color) !default;
$modal-content-border-width:        0 !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       $box-shadow-sm !default;
$modal-content-box-shadow-sm-up:    $box-shadow !default;

$modal-backdrop-bg:                 $gray-900 !default;
$modal-backdrop-opacity:            .85 !default;
$modal-header-border-color:         var(--app-component-modal-border-color) !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            $modal-inner-padding !default;
$modal-header-padding-x:            $modal-inner-padding !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-sm:                          300px !default;
$modal-md:                          500px !default;
$modal-lg:                          800px !default;
$modal-xl:                          1140px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;



// 29.0 UI - List Group

$list-group-color:                  var(--app-component-color) !default;
$list-group-bg:                     var(--app-component-bg) !default;
$list-group-border-color:           var(--app-component-border-color) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;

$list-group-item-padding-y:         $spacer * .75 !default;
$list-group-item-padding-x:         $spacer !default;
$list-group-item-bg-scale:          -80% !default;
$list-group-item-color-scale:       40% !default;

$list-group-hover-bg:               var(--app-component-hover-bg) !default;
$list-group-active-color:           var(--app-component-active-color) !default;
$list-group-active-bg:              var(--app-component-active-bg) !default;
$list-group-active-border-color:    var(--app-component-active-border-color) !default;

$list-group-disabled-color:         var(--app-component-disabled-color) !default;
$list-group-disabled-bg:            $list-group-bg !default;

$list-group-action-color:           var(--app-component-color) !default;
$list-group-action-hover-color:     $list-group-action-color !default;

$list-group-action-active-color:    var(--app-component-active-color) !default;
$list-group-action-active-bg:       var(--app-component-active-bg) !default;



// 30.0 UI - Alert

$alert-padding-y:                   $spacer !default;
$alert-padding-x:                   $spacer !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                0 !default;
$alert-bg-scale:                    -70% !default;
$alert-border-scale:                -70% !default;
$alert-color-scale:                 70% !default;
$alert-dismissible-padding-r:       $alert-padding-x * 3 !default;



// 31.0 UI - Card

$card-spacer-y:                     $spacer !default;
$card-spacer-x:                     $spacer !default;
$card-title-spacer-y:               $spacer * .5 !default;
$card-border-width:                 $border-width !default;
$card-border-color:                 #{rgba(var(--app-component-color-rgb), .1)} !default;
$card-border-radius:                $border-radius !default;
$card-box-shadow:                   null !default;
$card-inner-border-radius:          $card-border-radius !default;
$card-cap-padding-y:                $card-spacer-y * .5 !default;
$card-cap-padding-x:                $card-spacer-x !default;
$card-cap-bg:                       rgba($gray-800, .075) !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           var(--app-component-bg) !default;
$card-img-overlay-padding:          $spacer !default;
$card-group-margin:                 $grid-gutter-width * .5 !default;



// 32.0 UI - Accordion

$accordion-padding-y:                   1rem !default;
$accordion-padding-x:                   1.25rem !default;
$accordion-color:                       $body-color !default;
$accordion-bg:                          $body-bg !default;
$accordion-border-width:                $border-width !default;
$accordion-border-color:                rgba($gray-900, .125) !default;
$accordion-border-radius:               $border-radius !default;
$accordion-inner-border-radius:         $accordion-border-radius !default;

$accordion-body-padding-y:              $accordion-padding-y !default;
$accordion-body-padding-x:              $accordion-padding-x !default;

$accordion-button-padding-y:            $accordion-padding-y !default;
$accordion-button-padding-x:            $accordion-padding-x !default;
$accordion-button-color:                $accordion-color !default;
$accordion-button-bg:                   $accordion-bg !default;
$accordion-transition:                  $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:            tint-color($component-active-bg, 90%) !default;
$accordion-button-active-color:         shade-color($primary, 10%) !default;

$accordion-button-focus-border-color:   $input-focus-border-color !default;
$accordion-button-focus-box-shadow:     $btn-focus-box-shadow !default;

$accordion-icon-width:                  1.25rem !default;
$accordion-icon-color:                  $accordion-button-color !default;
$accordion-icon-active-color:           $accordion-button-active-color !default;
$accordion-icon-transition:             transform .2s ease-in-out !default;
$accordion-icon-transform:              rotate(-180deg) !default;

$accordion-button-icon:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;



// 33.0 UI - Dropdown

$dropdown-min-width:                10rem !default;
$dropdown-font-weight:              $font-weight-bold !default;
$dropdown-padding-x:                0 !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                $font-size-base !default;
$dropdown-color:                    var(--app-component-color) !default;
$dropdown-bg:                       var(--app-component-dropdown-bg) !default;
$dropdown-border-color:             #{rgba(var(--app-component-color-rgb), .1)} !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             0 !default;
$dropdown-inner-border-radius:      $dropdown-border-radius !default;
$dropdown-divider-bg:               $dropdown-border-color !default;
$dropdown-divider-margin-y:         $spacer * .5 !default;
$dropdown-box-shadow:               $box-shadow !default;

$dropdown-link-color:               #{rgba(var(--app-component-color-rgb), .85)} !default;
$dropdown-link-hover-color:         var(--app-component-color) !default;
$dropdown-link-hover-bg:            var(--app-component-dropdown-hover-bg) !default;
$dropdown-link-active-color:        var(--app-component-active-color) !default;
$dropdown-link-active-bg:           var(--app-component-active-bg) !default;
$dropdown-link-disabled-color:      $gray-500 !default;

$dropdown-item-padding-y:           $spacer * .33 !default;
$dropdown-item-padding-x:           $spacer !default;

$dropdown-header-color:             var(--app-component-color) !default;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x !default;
$dropdown-header-bg:                null !default;
$dropdown-header-font-size:         rem(10px) !default;

$dropdown-dark-color:               $gray-500 !default;
$dropdown-dark-bg:                  $gray-800 !default;
$dropdown-dark-border-color:        $dropdown-border-color !default;
$dropdown-dark-divider-bg:          $gray-700 !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       $gray-700 !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:        $gray-500 !default;



// 34.0 UI - Progress Bar

$progress-height:                   1rem !default;
$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       var(--app-component-secondary-bg) !default;
$progress-border-radius:            $border-radius !default;
$progress-box-shadow:               $box-shadow-inset !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   $primary !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;



// 35.0 UI - Toasts

$toast-max-width:                   350px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .5rem !default;
$toast-font-size:                   .8125rem !default;
$toast-color:                       null !default;
$toast-background-color:            #{rgba(var(--app-component-dropdown-bg-rgb), .98)} !default;
$toast-border-width:                0 !default;
$toast-border-color:                var(--app-component-dropdown-border-color) !default;
$toast-border-radius:               $border-radius !default;
$toast-box-shadow:                  $box-shadow !default;
$toast-spacing:                     $grid-gutter-width * .5 !default;

$toast-header-color:                var(--app-component-color) !default;
$toast-header-background-color:     #{rgba(var(--app-component-dropdown-bg-rgb), .85)} !default;
$toast-header-border-width:         1px !default;



// 36.0 UI - Badges

$badge-font-size:                   .75em !default;
$badge-font-weight:                 $font-weight-bold !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .35em !default;
$badge-padding-x:                   .65em !default;
$badge-border-radius:               $border-radius !default;



// 37.0 UI - Nav Link

$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              $font-weight-bold !default;
$nav-link-color:                    rgba(var(--app-component-color-rgb), .5) !default;
$nav-link-hover-color:              var(--app-component-color) !default;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:           $gray-400 !default;
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;



// 38.0 UI - Nav Tabs

$nav-tabs-border-color:             transparent !default;
$nav-tabs-border-width:             0 !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  transparent !default;
$nav-tabs-link-active-color:        var(--app-component-color) !default;
$nav-tabs-link-active-bg:           var(--app-component-bg) !default;
$nav-tabs-link-active-border-color: transparent !default;



// 39.0 UI - Nav Pills

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       var(--app-component-active-color) !default;
$nav-pills-link-active-bg:          var(--app-component-active-bg) !default;



// 40.0 UI - Navbar

$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           1rem !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        $btn-focus-width !default;
$navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                rgba($black, .55) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;
$navbar-dark-brand-color:           $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;



// 41.0 UI - Tooltip

$tooltip-font-size:                 $font-size-base !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $gray-900 !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   .95 !default;
$tooltip-padding-y:                 $spacer * .25 !default;
$tooltip-padding-x:                 $spacer * .5 !default;
$tooltip-margin:                    0 !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

$form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:     $tooltip-font-size !default;
$form-feedback-tooltip-line-height:   null !default;
$form-feedback-tooltip-opacity:       $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;



// 42.0 UI - Popover

$popover-font-size:                 $font-size-base !default;
$popover-bg:                        var(--app-component-bg) !default;
$popover-max-width:                 250px !default;
$popover-border-width:              0 !default;
$popover-border-color:              rgba($gray-900, .2) !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                $box-shadow !default;

$popover-header-bg:                 var(--bs-light) !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .65rem !default;
$popover-header-padding-x:          $spacer !default;

$popover-body-color:                var(--app-component-color) !default;
$popover-body-padding-y:            $spacer !default;
$popover-body-padding-x:            $spacer !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;
$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;



// 43.0 UI - Image Thumbnail

$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            $gray-300 !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              $box-shadow-sm !default;



// 44.0 UI - Figure

$figure-caption-font-size:          $small-font-size !default;
$figure-caption-color:              $gray-600 !default;



// 45.0 UI - Breadcrumb

$breadcrumb-font-size:              null !default;
$breadcrumb-font-weight:            $font-weight-base !default;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $gray-500 !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;



// 46.0 UI - Carousel

$carousel-control-color:             $white !default;
$carousel-control-width:             15% !default;
$carousel-control-opacity:           .5 !default;
$carousel-control-hover-opacity:     .9 !default;
$carousel-control-transition:        opacity .15s ease !default;

$carousel-indicator-width:           30px !default;
$carousel-indicator-height:          3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer:          3px !default;
$carousel-indicator-opacity:         .5 !default;
$carousel-indicator-active-bg:       $white !default;
$carousel-indicator-active-opacity:  1 !default;
$carousel-indicator-transition:      opacity .6s ease !default;

$carousel-caption-width:             70% !default;
$carousel-caption-color:             $white !default;
$carousel-caption-padding-y:         1.25rem !default;
$carousel-caption-spacer:            1.25rem !default;

$carousel-control-icon-width:        2rem !default;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$carousel-transition-duration:       .6s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-dark-indicator-active-bg:  $black !default;
$carousel-dark-caption-color:        $black !default;
$carousel-dark-control-icon-filter:  invert(1) grayscale(100) !default;



// 47.0 UI - Spinners

$spinner-width:                     2rem !default;
$spinner-height:                    $spinner-width !default;
$spinner-vertical-align:            -.125em !default;
$spinner-border-width:              .25em !default;
$spinner-animation-speed:           .75s !default;

$spinner-width-sm:                  1rem !default;
$spinner-height-sm:                 $spinner-width-sm !default;
$spinner-border-width-sm:           .2em !default;



// 48.0 UI - Close Button

$btn-close-width:                   1em !default;
$btn-close-height:                  $btn-close-width !default;
$btn-close-padding-x:               .25em !default;
$btn-close-padding-y:               $btn-close-padding-x !default;
$btn-close-color:                   $black !default;
$btn-close-bg:                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$btn-close-focus-shadow:            $input-btn-focus-box-shadow !default;
$btn-close-opacity:                 .5 !default;
$btn-close-hover-opacity:           .75 !default;
$btn-close-focus-opacity:           1 !default;
$btn-close-disabled-opacity:        .25 !default;
$btn-close-white-filter:            invert(1) grayscale(100%) brightness(200%) !default;



// 49.0 UI - Offcanvas

$offcanvas-padding-y:               $modal-inner-padding !default;
$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        400px !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
$offcanvas-border-color:            $modal-content-border-color !default;
$offcanvas-border-width:            $modal-content-border-width !default;
$offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                $modal-content-bg !default;
$offcanvas-color:                   $modal-content-color !default;
$offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;



// 50.0 UI - Code

$code-font-size:                    90% !default;
$code-color:                        darken($pink, 15%) !default;
$code-bg:                           lighten($pink, 30%) !default;
$code-padding-y:                    rem(2px) !default;
$code-padding-x:                    rem(6px) !default;
$code-border-radius:                $border-radius !default;

$kbd-padding-y:                     .2rem !default;
$kbd-padding-x:                     .4rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         $white !default;
$kbd-bg:                            $gray-900 !default;

$pre-color:                         null !default;



// 51.0 UI - Hr

$hr-margin-y:                       $spacer !default;
$hr-color:                          $gray-500 !default;
$hr-height:                         $border-width !default;
$hr-opacity:                        .4 !default;



// 52.0 UI - Panel

$panel-padding-x:                   $spacer !default;
$panel-padding-y:                   $spacer * 0.8 !default;
$panel-heading-padding-x:           $panel-padding-x !default;
$panel-heading-padding-y:           $panel-padding-y !default;
$panel-body-padding-x:              $spacer !default;
$panel-body-padding-y:              $spacer !default;
$panel-margin-bottom:               $spacer * 1.33 !default;
$panel-bg-color:                    var(--app-component-bg) !default;
$panel-header-inverse-bg:           $gray-900 !default;
$panel-header-inverse-color:        $white !default;
$panel-header-default-bg:           tint-color($gray-500, 80%) !default;
$panel-header-default-color:        $gray-900 !default;
$panel-toolbar-padding-x:           $panel-padding-x !default;
$panel-toolbar-padding-y:           $panel-padding-y !default;
$panel-footer-padding-x:            $panel-padding-x !default;
$panel-footer-padding-y:            $panel-padding-y !default;