// Footer.scss
.custom-footer {
    // Initial styles
    margin-left: 250px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px; /* Adjust padding as needed */

    @media (max-width: 767.98px) {
        margin-left: 10px;  // Set margin-left to 0 for screens less than or equal to 767.98px
    }
}